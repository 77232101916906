@font-face {
    font-family: "Rubik";
    src: url('../webfonts/Rubik/static/Rubik-Regular.ttf') format('truetype');
}
@font-face {
    font-family: "Noto Sans Mono";
    src: url('../webfonts/Noto_Sans_Mono/static/NotoSansMono/NotoSansMono-Regular.ttf') format('truetype');
}
html {
    font-size: 100% !important;
}    

h1 {
    font-weight: bold !important;
}
h2 {
    font-weight: bold !important;
}
h3 {
    font-weight: bold !important;
}
h4 {
    font-weight: bold !important;
}

@media only screen and (min-width: 1000px) {
    h1 {
        font-size: 3vw !important;
    }
    h2 {
        font-size: 2vw !important;
    }
    h3 {
        font-size: 1.8vw !important;
    }
    h4 {
        font-size: 1.5vw !important;
    }
    span {
        font-family: 'Rubik' !important;
        font-size: 1.3vw;
    }
    p {
        font-family: 'Rubik' !important;
        font-size: 1.3vw;
    }
    ul {
        font-family: 'Rubik' !important;
        font-size: 1.3vw;
    }
    .keywords {
        font-size: 1vw !important;
    }
    .fa-book {
        font-size: 1vw !important;
    }
}



@media only screen and (max-width: 1000px) {
    h1 {
        font-size: 5vw !important;
    }
    h2 {
        font-size: 4vw !important;
    }
    h3 {
        font-size: 3.8vw !important;
    }
    h4 {
        font-size: 3.5vw !important;
    }
    p {
        font-family: 'Rubik' !important;
        font-size: 3vw !important;
    }
    span {
        font-family: 'Rubik' !important;
        font-size: 3vw !important;
    }
    ul {
        font-family: 'Rubik' !important;
        font-size: 3vw !important;
    }
    .keywords {
        font-size: 2vw !important;
    }
}

.navbar-nav {
    font-size: 1em !important;
}


:root {
    --moon-blue: #002b36;
    --light-white: #fffff9;
    /* --light-white: rgb(249, 250, 254); */
    --bg-opacity: 0.2;
    --bg-color: #50696F;
}

/* Navbar  */

nav {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    background-color: var(--light-white);
    box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    /* font-size: larger; */
    font-family: 'Noto Sans Mono';
}
.nav-link {
    cursor: pointer;
}

.nav-menu {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    gap: 10px;
}

@media only screen and (min-width: 500px) {
    .navbar-nav {
        margin-left: auto;
        flex-direction: row !important;
        --bs-nav-link-padding-x: 0.5rem !important;
        --bs-nav-link-padding-y: 0rem !important;
    }
    .footer-contacts{
        display: flex;
        gap: 45px;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (max-width: 700px) {
    .footer-contacts{
        display: flex;
        gap: 0px;
        justify-content: center;
        align-items: center;
    }
}

.jh-logo {
    height: 110%;
}

#navbarText {
    margin-left: auto;
}

.navbar-hide {
    pointer-events: none;
    opacity: 0;
}

.hamburger {
    cursor: pointer;
    font-size: 1.5rem;
}

a:link {
 text-decoration: none;
 color: rgba(0,118,142,1);
}

/* Remove underline from visited links */
a:visited {
 text-decoration: none;
 color: var(--moon-blue);
}

/* Remove underline from links on hover */
a:hover {
 text-decoration: none;
}

/* Remove underline from active links */
a:active {
 text-decoration: none;
}

