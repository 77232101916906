.about-section{
    height: 90vh;
    background: rgb(156,185,193);
    background: linear-gradient(290deg, rgba(156,185,193,1) 0%, rgba(255,255,249,1) 60%);
    /* background-color: var(--light-white); */
    color: var(--moon-blue); 
    box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}


@media only screen and (max-width: 1000px) {
    .about-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .about-section{
        height: fit-content;
        padding-bottom: 50px;
    }
    .about-container {
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .about-article {
        margin: 5vw;
    }
    .my-image-container {
        height: 80vh;
    }
    .my-image {
        width: min(80vw,80vh);
        height: min(80vw,80vh);
    }
}
@media only screen and (min-width: 1000px) {
    .about-content {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 55% 45%;
    }
    .about-article {
        top: 45%;
        transform: translateY(-45%);
        position: relative;
        margin-left: 5vw;
    }
    .about-container {
        height: 90vh;
    }
    .my-image {
        width: 80%;
    }
}

.keywords {
    color: rgba(0,118,142,1);
    float: left;
    padding-left: 0px;
}
.keywords li {
    display: inline-block;
    padding: 10px;
}
.about-article p em {
    cursor: pointer;
}
.about-article p {
    margin-bottom: 0px;
}


.my-image {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}

.my-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}
