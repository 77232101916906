#content-lattice-container{
    width:33vw;
    height:90vh;
    background: linear-gradient(90deg, rgba(0,43,54,1) 12%, rgba(0,118,142,1) 100%);
    color: var(--light-white); 

    box-shadow:  10px 0 20px -12px rgba(0, 0, 0, 0.42),  3px 0 20px 0 rgba(0, 0, 0, 0.12),  8px 0 10px -5px rgba(0, 0, 0, 0.2);
}
.content-container{
    width:66vw;
    height:90vh;
    background-color: var(--light-white);
}

#research-component {
    display: flex;
    box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.concept {
    cursor: pointer;
}
.paper-center {
}
.paper-center-content{
    width: 80%;
    text-align: left;
}
.paper-content-header {
    display: flex;
}

.paper-content-heading{
    width: 95%;
}

.bib-link{
    width: 5%;
    font-size: larger;
    transform: translateY(-10%);
}

#content-lattice-info {

}

.content-container{
    height:90vh;
    overflow: scroll;
}

.paper-content-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.research-img {
    width: 100%;
}
.research-img-container {
    box-shadow: 0 20px 60px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    width: 70%;
}

@media only screen and (max-width: 1000px) {
    .content-container{
        width: 100vw;
        height: fit-content;
    }
    
    .odd-research {
        background: linear-gradient(290deg, rgba(156,185,193,1) 0%, rgba(255,255,249,1) 60%);
    }
    .even-research {
        background: linear-gradient(290deg, rgba(255,255,254,1) 0%, rgba(255,250,235,1) 60%);
    }
}

@media only screen and (min-width: 1000px) {
    .paper-content-container {
        min-height: 65vh;
    }
}

.research-heading-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.research-heading {
    width: 80vw;
    font-weight: bold;
}


