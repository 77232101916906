@media only screen and (min-width: 1000px) {
    .project-left {
        min-height: 90vh;
        display: flex;
        background: linear-gradient(290deg, rgba(255,255,254,1) 0%, rgba(255,250,235,1) 60%);
    }
    
    .project-right {
        min-height: 90vh;
        display: flex;
        background: linear-gradient(290deg, rgba(156,185,193,1) 0%, rgba(255,255,249,1) 60%);
    }
    .project-image-container {
        width: 50%;
        margin: 5%;
    }

    .project-description-container {
        width: 42%;
        margin: 5%;
    }
}

@media only screen and (max-width: 1000px) {
    .project-left {
        min-height: 90vh;
        display: flex;
        flex-direction: column;
        background: linear-gradient(290deg, rgba(255,255,254,1) 0%, rgba(255,250,235,1) 60%);
    }
    
    .project-right {
        min-height: 90vh;
        display: flex;
        flex-direction: column;
        background: linear-gradient(290deg, rgba(156,185,193,1) 0%, rgba(255,255,249,1) 60%);
    }
    .project-image-container {
        width: 90%;
        margin: 5%;
    }

    .project-description-container {
        width: 90%;
        min-height: 50vh;
        margin: 5%;
    }
}

.project-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: center;
}

.project-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: center;
}

.project-image{
    box-shadow: 0 20px 60px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin: 20px;
}

#conexp-img-container{
   background-color: var(--moon-blue);
   width: 100%;
}

#conexp-img{
   margin: 10px;
}


#projects {
    box-shadow: 0 20px 60px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.project-image-link {
    width: 100%;
}
