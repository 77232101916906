.contact-section{
    display: flex;
    height: auto;
    background-color: var(--light-white);
    box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.contact-me {
    display: flex;
    justify-content: center;
}


#contact-map-json{
    text-align: left;
    width: fit-content;
}

.contact-map {
    font-size: larger;
    padding: 25px;
}

@media only screen and (min-width: 1000px) {
    .contact-map {
        height: 90vh;
        font-size: 1vw;
    }
    .contact-me {
        width: 50vw;
        height: 90vh;
    }
    #contact-map-json {
        top: 50%;
        transform: translateY(-50%);
        position: relative;
    }
}

@media only screen and (max-width: 1300px) {

}



@media only screen and (max-width: 1000px) {
    .contact-section{
        flex-direction: column;
    }
    .contact-me {
        height: 30vh;
    }
    .contact-map {
        height: fit-content;
        font-size: 2vw;
    }

}

#contact-container {
    max-width: 1000px;
    height: 50vh;
}
#contact-center {
    top: 50%;
    transform: translateY(-50%);
    position: relative;
}


.contact-me-text {
    top: 40%;
    transform: translateY(-40%);
    position: relative;
    height: fit-content;
}

